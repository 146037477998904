import { FC, ReactNode } from 'react'
import { css } from '@emotion/react'
import { palette, PaletteKeys } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'

const styles = {
  root: css({
    display: 'inline-block',
    borderRadius: 4,
    background: '#fff',
    textAlign: 'center'
  }),
  large: css(
    {
      padding: '0 8px'
    },
    typography.textS
  ),
  default: css(
    {
      padding: '0 4px'
    },
    typography.textXS
  ),
  emphasis: css({
    fontWeight: 'bold'
  })
}

type LabelColors = PaletteKeys | string
const isPaletteKeys = (arg: LabelColors): arg is PaletteKeys => arg in palette

const makeColor = (colorKey: LabelColors) =>
  isPaletteKeys(colorKey)
    ? css({
        border: `1px solid ${palette[colorKey]}`,
        color: palette[colorKey]
      })
    : css({
        border: `1px solid ${colorKey}`,
        color: colorKey
      })

export interface LabelProps {
  color?: LabelColors
  large?: boolean
  emphasis?: boolean
  children?: ReactNode
}

export const Label: FC<LabelProps> = (props) => {
  const { color = 'main02', large, emphasis } = props
  return (
    <span
      css={[
        styles.root,
        makeColor(color),
        large ? styles.large : styles.default,
        emphasis && styles.emphasis
      ]}
    >
      {props.children}
    </span>
  )
}
