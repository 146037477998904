import { FC } from 'react'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import StarRoundedIcon from '@material-ui/icons/StarRounded'

const styles = {
  root: css({
    display: 'inline-flex',
    alignItems: 'center'
  }),
  star: (large: RatingProps['large']) =>
    css({
      display: 'flex',
      color: palette.gray3,
      position: 'relative',
      fontSize: fontSize(large)
    }),
  rate: (point: RatingProps['point']) =>
    css({
      display: 'flex',
      width: `calc(${point} * 1em)`,
      color: palette.main,
      position: 'absolute',
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    }),
  value: css({
    color: palette.main03,
    fontWeight: 'bold',
    marginLeft: 4
  })
}

export interface RatingProps {
  point: number
  large?: boolean
}

const fontSize = (large?: boolean) => (large ? 20 : 16)

export const Rating: FC<RatingProps> = (props) => {
  const { point, large } = props
  const iconStyle = { fontSize: fontSize(large) }
  return (
    <div css={styles.root}>
      <span
        css={styles.star(large)}
        aria-label={`5点中${point}点の評価`}
        role="img"
      >
        <span css={styles.rate(point)}>
          <StarRoundedIcon style={iconStyle} />
          <StarRoundedIcon style={iconStyle} />
          <StarRoundedIcon style={iconStyle} />
          <StarRoundedIcon style={iconStyle} />
          <StarRoundedIcon style={iconStyle} />
        </span>
        <StarRoundedIcon style={iconStyle} />
        <StarRoundedIcon style={iconStyle} />
        <StarRoundedIcon style={iconStyle} />
        <StarRoundedIcon style={iconStyle} />
        <StarRoundedIcon style={iconStyle} />
      </span>
      <span css={[large ? typography.textL : typography.textM, styles.value]}>
        {point.toFixed(1)}
      </span>
    </div>
  )
}
